(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': { 
      init: function() {
        // <!--- PUT STUFF IN THIS FUNCTION !!! JavaScript to be fired on all pages
        
        /* ----------------------------------------------------
          Notifications banner placement */
        function notifications_banner() {
          if($("#notifications-banner").length > 0) {
            if($("#notifications-banner").hasClass('fixed-bottom')) {
              $('body').css('padding-bottom', $("#notifications-banner").outerHeight());
            }
            else {
              $('body').css('padding-top', $("#notifications-banner").outerHeight());
            }
          }
        }
       /* End notifications banner 
           ----------------------------------------------------*/

        function body_padding() {
          if($(window).width() < 992) {
            var pad = $('.layout-top').outerHeight();

            $('body').css('padding-top', pad);
            $('.offcanvas-collapse').css('top', pad);

            //pb-0 pb-md-2h w-100 navbar-collapse offcanvas-collapse open

            if($('body').hasClass('logged-in')) {
              $('.offcanvas-collapse').css('top', pad + $('#wpadminbar').height());
            }
          }
          else {
            $('body').css('padding-top', 0);
          }
        }

        
        $(window).resize(function() { 
          notifications_banner(); 
          body_padding();
        }); // onresize
        $(window).load(function(){ 
          notifications_banner(); 
          body_padding();
        }); // onload     
        
        $(window).on("load", function (e) {
          body_padding();
          $('a[href^="#"]:not([role="tab"]').on('click',function (e) {
              e.preventDefault();

              var target = this.hash;
              var $target = $(target);

              if($target.length>0) {

                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - 200
                }, 900, 'swing', function () {
                    window.location.hash = target;
                });
              }
          });

        }); 

        // Lazy Carousel
        $('#tour-gallery').on('slide.bs.carousel', function() {
          var $nextImage = $('.active.carousel-item', this).next('.carousel-item').find('img');
          $nextImage.attr('src', $nextImage.data('lazy'));
        });

        // Menu
        var $navbar_toggler = $('#navbar-toggler'); 

        $('[data-toggle="offcanvas"]').on('click tap press', function (e) {
          $('.offcanvas-collapse').toggleClass('open');
          //$('#header').toggleClass('position-fixed');
          $navbar_toggler.toggleClass('collapsed');
        });

        // Matchheight()
        $('.tour-tile').matchHeight(); 
        $('.block-tour-teasers .tour-teaser .title').matchHeight();
        $('.block-tour-teasers .tour-teaser .teaser-info').matchHeight();
        
        // Forms
        $('.gform_wrapper input').addClass('form-control');
        $('.ginput_container_text input').addClass('form-text');
        $('.ginput_container_email input').addClass('form-text form-email');
        //$('.gform_button').addClass('btn btn-default').removeClass('form-control');
        $('.gfield_label').addClass('sr-only');

        // Screenreader
        $('.screen-reader-text').addClass('sr-only');

        
        // Go to link in block
        $(".follow-link").css('cursor', 'pointer').on('click tap press', function(e){

          if(e.target.nodeName === 'I') {
            return false;
          }

          var $href = $(this).find("a:not(.flag)").attr("href");

          e.preventDefault();

          if($href.length>0) {
            window.location = $href;
          }

          return false;
        });

        // ScrollReveal
        window.sr = new ScrollReveal();
        sr.reveal('h1, .reveal, .block-testimonials, .block-subscribe');
        // Reveal in a ripple efffect
        sr.reveal('.block-perks .perk, .post-teaser', { duration: 800 }, 70);
        sr.reveal('.main .post-full > section', { duration: 500 }, 100);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        /*
         * Replace all SVG images with inline SVG
         */
        $('img.svg').each(function(){
            var $img     = jQuery(this);
            var imgID    = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL   = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                  $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });

      }
    },
    // // Home page
    'home': {
      init: function() {
        var perks = $(".perk");

        perks.each(function(x) {
          var rand = Math.floor((Math.random() * 100) + 1);
          $(this).addClass('ani-1');
        }); 


        function hero_adjust() {
          $('.hero-text-wrapper .cta-button').css('min-width', $(".hero-text-wrapper h1").width());
        }


        $(window).resize(function() { hero_adjust(); }); // onresize
        $(window).load(function(){ hero_adjust(); }); // onload 
        
        // $('.layout-content, .layout-canyon, .layout-canyon2, .layou3').addClass('layout-fs');
      

          var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
              triggerHook: 'onLeave'              
            }
          });

          var slides = ['#layout-canyon2', '#layout-canyon3', '#footer']; 

          new ScrollMagic.Scene({ triggerElement: '#layout-content', duration: 500 })
            .setPin('#layout-content')
            .addTo(controller);

          // create scene for every slide
          // for (var i=0; i<slides.length; i++) {
          //   $(slides[i]).addClass('layout-fs');
          //   new ScrollMagic.Scene({ triggerElement: slides[i] })
          //     .setPin(slides[i])
          //     .addTo(controller);
          // }



      }
    },

        // Template directory pages
    'template_contact': {
      init: function() {

          var infowindow;

          function add_marker( $marker, map ) {

            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
            
            // var icon
            var image = {
              url: stylesheet_directory_uri + '/dist/images/icons/pin.png', // image is 512 x 512
              scaledSize : new google.maps.Size(30, 47)
            };

            // create marker
            var marker = new google.maps.Marker({
              position  : latlng,
              map       : map,
              icon      : image
            });

            // add to array
            map.markers.push( marker );

            // if marker contains HTML, add it to an infoWindow
            if( $marker.html() ) {
              // create info window
              infowindow = new google.maps.InfoWindow({
                content   : $marker.html()
              });

              // show info window when marker is clicked
              google.maps.event.addListener(marker, 'click', function() {
                // Close others
                if (infowindow) {
                  infowindow.close();
                }
                
                infowindow.open( map, marker );
                
              });
            }

          }

          function center_map( map, zoom ) {

            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each( map.markers, function( i, marker ){

              var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

              bounds.extend( latlng );

            });

            // only 1 marker?
            if( map.markers.length === 1 )
            {
              // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( zoom );
            }
            else
            {
              // fit to bounds
              map.fitBounds( bounds );
            }

          }

          function new_map( $el ) {
            
            // var
            var $markers = $el.find('.marker');

            var map_styles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all"
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": "-14"
            },
            {
                "lightness": "-9"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#7aafd1"
            },
            {
                "visibility": "on"
            }
        ]
    }
];
            
            
            // vars
            var args = {
              zoom      : 11,
              styles    : map_styles,
              center    : new google.maps.LatLng(0, 0),
              mapTypeId : google.maps.MapTypeId.ROADMAP
            };
            
      
            // create map           
            var map = new google.maps.Map( $el[0], args);
            
            
            // add a markers reference
            map.markers = [];
            
            
            // add markers
            $markers.each(function(){
              add_marker( $(this), map );
            });

            google.maps.event.addListener(map, "click", function(event) {
              infowindow.close();
            });
                        
            
            // center map
            center_map( map, args.zoom );
            
            // return
            return map;
            
          }

          var map = null;
          $('.acf-map').each(function(){
            // create map
            console.log('Load Google map...');
            map = new_map( $(this) );
          });

      }
    }


  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
